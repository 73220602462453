#app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

@font-face {
  font-family: helveticaNeue;
  src: url(/public/HelveticaNeueLTStd-Bd.otf);
}

body {
  margin: 0;
  font-family: helveticaNeue;
  letter-spacing: -1px;
  -moz-osx-font-smoothing: grayscale;
}


.swiper {
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
}
.swiper-wrapper > .swiper-slide, swiper-slide{
  width: 33vw;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin: 0 !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  color: inherit;
}

@media (max-width: 768px) {
  .swiper-wrapper > .swiper-slide, swiper-slide {
    width: 100vw;
  }
} 

body {
    -webkit-font-smoothing: subpixel-antialiased;
}

pre {
    font-family: helveticaNeue;
    text-wrap: wrap;
}